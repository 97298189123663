<template>
  <div ref="branch" v-lazyChart="{ fn: typeChange }" class="container">
    <div class="title">成绩分布</div>
    <div class="sub-title">成绩分布</div>
    <div class="operate">
      <div>
        数据源：
        <a-radio-group v-model="dataSource" @change="dataSourceChange">
          <a-radio :value="1"> 原始分 </a-radio>
          <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="2">
            赋分</a-radio
          >
        </a-radio-group>
      </div>
      <div v-if="type == 1" class="scoreSegmentation">
        分数段区间：
        <span>上限：</span>
        <a-input v-model.number="search.high" style="width: 64px" />
        <span>分</span>
        <span>下限：</span>
        <a-input v-model.number="search.low" style="width: 64px" />
        <span>分</span>
        <span>间隔：</span>
        <a-input v-model.number="search.interval" style="width: 64px" />
        <span>分</span>
        <a-button type="primary" @click="getBranchData">确定</a-button>
      </div>
      <div v-if="type == 2" class="scoreSegmentation">
        排名区间：
        <a-input v-model.number="search.low" style="width: 64px" />
        <span>名</span>
        <span>-</span>
        <a-input v-model.number="search.high" style="width: 64px" />
        <span>名</span>

        <span>间隔：</span>
        <a-input v-model.number="search.interval" style="width: 64px" />
        <span>名</span>
        <a-button type="primary" @click="getBranchRank">确定</a-button>
      </div>
    </div>
    <div class="switch">
      <a-radio-group
        v-if="$route.query.examType != 1"
        v-model="type"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button :value="1"> 成绩分布 </a-radio-button>
        <a-radio-button :value="2"> 排名分布 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="charts">
      <chart
        :key="tokenKey"
        :define-options="defineOptions"
        :series="chartSeries"
        :data-zoom="20"
      />
    </div>
    <div class="tip">
      <div class="intent">注：</div>
      <div class="text">
        <div>
          以上数据结果均以{{
            dataSource == 1 ? "原始分" : "赋分"
          }}进行统计运算。
        </div>
        <div>红色柱表示校平均分所在分数段。</div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../components/charts.vue";
import {
  getrenkejiaoshireportscoredistribution,
  getrenkejiaoshireportscoredistributionrank,
} from "@/core/api/newExamAcademic/teacher";
import { unionGetrenkejiaoshireportscoredistribution } from "@/core/api/newExamAcademic/union/teacher";
export default {
  components: {
    chart,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
    classNum: {
      type: [String, Number],
      default: "",
    },
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      category: 1,
      type: 1,
      search: {
        high: null,
        interval: null,
        low: null,
      },
      dataSource: 1,
      chartSeries: [],
      tokenKey: Math.random(),
      defineOptions: {},
      chartData: [],
    };
  },
  mounted() {
    if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
      this.dataSource = 1;
    }
    if (this.type == 1) {
      let topData = this.topData;
      this.search.high = topData.segmentMaximum;
      this.search.low = topData.segmentMinimum;
      this.search.interval = topData.segmentGap;
    } else if (this.type == 2) {
      let topData = this.topData;
      this.search.high = topData.rankMinimum;
      this.search.low = topData.rankMaximum;
      this.search.interval = topData.segmentGap;
    }
  },
  methods: {
    isNumber() {
      return (
        /^[0-9]*$/.test(this.search.high) &&
        /^[0-9]*$/.test(this.search.low) &&
        /^[0-9]*$/.test(this.search.interval)
      );
    },
    async getBranchData(loadingInstance, io) {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetrenkejiaoshireportscoredistribution({
          id: this.$route.query.id,
          ...this.search,
          classNum: this.classNum,
          dataSource: this.dataSource,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getrenkejiaoshireportscoredistribution({
          id: this.$route.query.id,
          ...this.search,
          classNum: this.classNum,
          dataSource: this.dataSource,
          subjectId: this.subjectId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      this.chartData = res.data.data;
      this.setChart();
      this.closeLoading(loadingInstance, io);
    },
    async getBranchRank() {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      const res = await getrenkejiaoshireportscoredistributionrank({
        id: this.$route.query.id,
        ...this.search,
        classNum: this.classNum,
        dataSource: this.dataSource,
        subjectId: this.subjectId,
      });
      this.chartData = res.data.data;
      this.setChart();
    },
    dataSourceChange() {
      if (this.type == 1) {
        this.getBranchData();
      } else {
        this.getBranchRank();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.branch);
        });
      }
    },
    handleChange() {},
    handleClick(item) {
      this.search.resEleccombId = item.id;
    },
    checkAll() {
      this.search.resEleccombId = null;
    },
    onChange() {},
    setChart() {
      if (this.type === 1) {
        this.defineOptions = {
          grid: {
            containLabel: true,
          },
          xAxis: {
            data: [...this.chartData.map((item) => item["segment"])],
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: "45",
            },
            type: "category",
          },
        };
        this.chartSeries = [
          {
            data: [
              ...this.chartData.map((item) => {
                if (item.isClassAverage == 1 || item.isGradeAverage == 1) {
                  return {
                    value: item["total"] || 0,
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  };
                } else {
                  return item["total"] || 0;
                }
              }),
            ],
            type: "bar",
            barMaxWidth: 40,

            label: {
              position: "top",
              color: "inherit",
              show: true,
            },
          },
        ];
        this.tokenKey = Math.random();
      } else if (this.type === 2) {
        this.defineOptions = {
          grid: {
            containLabel: true,
          },
          xAxis: {
            data: [...this.chartData.map((item) => item.name)],
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: "45",
            },
            type: "category",
          },
        };
        this.chartSeries = [
          {
            data: [
              ...this.chartData.map((item) => {
                if (item.isClassAverage == 1 || item.isGradeAverage == 1) {
                  return {
                    value: item["total"] || 0,
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  };
                } else {
                  return item["total"] || 0;
                }
              }),
            ],
            type: "bar",
            barMaxWidth: 40,

            label: {
              position: "top",
              color: "inherit",
              show: true,
            },
          },
        ];
        this.tokenKey = Math.random();
      }
    },
    typeChange(loadingInstance, io) {
      if (this.type === 1) {
        this.search.high = this.$parent.topData.segmentMaximum;
        this.search.low = this.$parent.topData.segmentMinimum;
        this.search.interval = this.$parent.topData.segmentGap;
        this.getBranchData(loadingInstance, io);
      } else if (this.type === 2) {
        let { rankMinimum, rankMaximum, rankGap } = this.$parent.topData;
        this.search.high = rankMaximum;
        this.search.low = rankMinimum;
        this.search.interval = rankGap;
        this.getBranchRank();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .scoreSegmentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    span {
      margin: 0 8px;
    }
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 18px;
}
</style>

<template>
  <div ref="student" v-lazyChart="{ fn: getCondition }" class="container">
    <div class="title">重点关注学生</div>
    <div class="operate">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button
          v-if="isChoosenSubject && $route.query.examType != 1"
          :value="5"
        >
          选科临界生
        </a-radio-button>
        <a-radio-button :value="1"> 波动生 </a-radio-button>
        <a-radio-button :value="2"> 拔尖生 </a-radio-button>
        <a-radio-button :value="3"> 后进生 </a-radio-button>
        <a-radio-button :value="4"> 跟踪生 </a-radio-button>
      </a-radio-group>
    </div>

    <div class="charts">
      <exportBtn
        style="margin-bottom: 18px"
        align="left"
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 2,
          classNum: classNum,
          subjectId: subjectId,
          topOrBackward: topOrBackward,
          upCompareId: wave.compareStatId,
        }"
      >
        <div v-if="type === 1" class="filter">
          <a-radio-group
            v-model="waveType"
            button-style="solid"
            @change="typeChange"
          >
            <a-radio-button value="upStudents"> 上升波动 </a-radio-button>
            <a-radio-button value="downStudents"> 下降波动 </a-radio-button>
          </a-radio-group>
          <div>
            选考的对比考试为：
            <a-select
              v-model="wave.compareStatId"
              not-found-content="暂无数据"
              style="width: 450px"
              @change="getWaveData"
            >
              <a-select-option
                v-for="(item, index) in selectOptions"
                :key="'selectOptions' + index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div v-else-if="type === 2" class="filter">
          校排名前：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button
            style="margin-left: 8px"
            type="primary"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
        <div v-else-if="type === 3" class="filter">
          校排名后：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></a-input
          ><a-button
            style="margin-left: 8px"
            type="primary"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
      </exportBtn>
    </div>
    <template v-if="type === 5">
      <div class="critical">
        <a-table
          :key="'critital' + chartKey"
          :columns="critialColumns"
          :scroll="{ x: 'max-content' }"
          style="margin-bottom: 18px"
          :data-source="crititalData"
          :pagination="false"
          bordered
        ></a-table>
        <a-table
          :key="'a-table' + chartKey"
          :columns="criticalCol"
          :data-source="tableData"
          :pagination="false"
          bordered
          style="flex: 1"
        >
        </a-table>
      </div>
    </template>
    <a-table
      v-if="type != 5"
      :key="chartKey"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      :row-key="(record, index) => index"
    >
      <div slot="name" slot-scope="text, record" style="text-align: center">
        <a-button type="link" @click="handleClick(record, record)"
          >{{ record.name }}
        </a-button>
      </div>
      <div
        slot="upStudentList"
        slot-scope="text, record"
        style="text-align: left"
      >
        <a-button
          v-for="(item, index) in record.studentList"
          :key="index"
          type="link"
          @click="handleClick(record, item)"
          >{{ item.name }}
        </a-button>
      </div>
    </a-table>

    <div v-if="type != 5" class="tip" style="margin-top: 18px">
      <div v-if="type === 1">
        <div>
          波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
        </div>
        <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 2">
        <div>
          拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
        </div>
        <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 3">
        <div>
          后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
        </div>
        <div>注：1.标蓝学生为潜力生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 4">
        <div>
          跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
        </div>
        <div>
          一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
        </div>
        <div>
          二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import chart from "../../components/charts.vue";
import { getstatcomparelist } from "@/core/api/academic/common";
import { getSubjectList } from "@/core/util/util";
// import scatter from "./components/scatter.vue";
import {
  getSubjectCritical,
  waveStudent,
  topOrBackward,
  trackStudent,
} from "@/core/api/newExamAcademic/teacher";
import {
  getUnionWave,
  getUnionToporbackward,
  getUnionTrack,
} from "@/core/api/newExamAcademic/union/teacher";
import { exportTeacherReportNew } from "@/core/api/academic/teacher";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  components: {
    // chart,
    // scatter,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
    classNum: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    let subjectList = getSubjectList();
    return {
      requestFunc: exportTeacherReportNew,
      type: 5,
      wave: {},
      critialColumns: [],
      crititalData: [],
      waveType: "upStudents",
      selectOptions: [],
      series: [],
      defineOptions: {},
      tableData: [],
      columns: [],
      classNumList: [],
      topOrBackward: 20,
      checkClassNum: [],
      chartKey: Math.random(),
      waveCol: [
        // 波动生
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "rankWave",
          title: "校排名增幅",
          align: "center",
          width: 100,
          customRender: (text, record) => {
            if (record.rankWave > 0) {
              return (
                <span>
                  <a-icon type="arrow-up" style="color:#67c23a" />
                  {record.rankWave}
                </span>
              );
            } else {
              return (
                <span>
                  <a-icon type="arrow-down" style="color:#ED2424" />
                  {record.rankWave}
                </span>
              );
            }
          },
        },
        {
          dataIndex: "score",
          title: "本次成绩",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "rank",
          title: "本次校排名",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "compareScore",
          title: "对比考试成绩",
          align: "center",
          width: 120,
        },
        {
          dataIndex: "compareRank",
          title: "对比考试排名",
          align: "center",
          width: 120,
        },
      ],
      topCol: [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          dataIndex: "totalScore",
          title: "总分",
          align: "center",
        },
        {
          dataIndex: "totalRank",
          title: "总分排名",
          align: "center",
        },
        {
          dataIndex: "score",
          title: "分数",
          align: "center",
        },
        {
          dataIndex: "rank",
          title: "排名",
          align: "center",
        },
      ],
      trackCol: [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "weak",
          title: "薄弱情况",
          align: "center",
          width: 100,
          customRender: (text, records) => {
            let enmu = {
              0: "-",
              1: "稍薄弱",
              2: "严重偏科",
            };
            return enmu[records.weak];
          },
        },
        {
          dataIndex: "totalScore",
          title: "总分",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "totalRank",
          title: "总分排名",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "score",
          title: "分数",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "rank",
          title: "排名",
          align: "center",
          width: 85,
        },
      ],
      subjectList: [...subjectList],
      isChoosenSubject: false,
      criticalCol: [
        {
          title: "风险生(后五名）名单",
          dataIndex: "riskStudents",
          width: "50%",
          customRender: (text, record) => {
            let data =
              record.extend || record.riskStudents.length <= 9
                ? record.riskStudents
                : record.riskStudents.slice(0, 9);
            let button;
            if (record.riskStudents.length > 9) {
              button = record.extend ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", false)}
                >
                  收起
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", true)}
                >
                  查看更多
                </a-button>
              );
            }

            return (
              <div class="nameList">
                <span style="margin-bottom: 8px; margin-right: 8px">
                  {record.level}
                </span>
                {...data.map((item) => (
                  <a-button
                    type="link"
                    vOn:click={() => this.handleClick(record, item)}
                  >
                    {item.name}
                  </a-button>
                ))}
                {button}
              </div>
            );
          },
        },
        {
          title: "潜力生(前五名）名单",
          width: "50%",
          dataIndex: "potentialStudents",
          customRender: (text, record) => {
            let data =
              record.extendPo || record.potentialStudents.length <= 9
                ? record.potentialStudents
                : record.potentialStudents.slice(0, 9);
            let button;
            if (record.potentialStudents.length > 9) {
              button = record.extendPo ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", false)}
                >
                  收起
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", true)}
                >
                  查看更多
                </a-button>
              );
            }

            return (
              <div class="nameList">
                <span style="margin-bottom: 8px; margin-right: 8px">
                  {record.level}
                </span>
                {...data.map((item) => (
                  <a-button
                    type="link"
                    vOn:click={() => this.handleClick(record, item)}
                  >
                    {item.name}
                  </a-button>
                ))}
                {button}
              </div>
            );
          },
        },
      ],
    };
  },
  mounted() {
    // this.typeChange();
    if (this.$route.examType != 1) {
      if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.isChoosenSubject = false;
        this.type = 1;
      } else {
        this.isChoosenSubject = true;
        this.type = 5;
      }
    } else {
      this.isChoosenSubject = false;
      this.type = 1;
    }
  },
  methods: {
    handleClick(record, item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: this.subjectId,
          classNum: this.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },

    getCondition(loadingInstance, io) {
      if (this.$route.query.examType == 1) {
        this.type = 1;
      }
      this.typeChange();
      this.closeLoading(loadingInstance, io);
    },
    async getWaveData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getUnionWave({
          ...this.wave,
          id: this.$route.query.id,
          isAllClass: 0,
          subjectId: this.subjectId,
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await waveStudent({
          ...this.wave,
          id: this.$route.query.id,
          isAllClass: 0,
          subjectId: this.subjectId,
          classNum: this.classNum,
        });
      }

      this.$nextTick(() => {
        this.tableData = res.data.data[this.waveType];
        // this.filterChartOptionsByWave(this.tableData);
      });
    },
    searchSubmit() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("请输入正整数");
        return;
      } else {
        this.getTopOrBackward();
      }
    },
    filterChartOptionsByWave(data) {
      if (data.length) {
        const compareRank = data.map((item) => item.compareRank);
        const rank = data.map((item) => item.rank);
        const maxCompareRank = Math.max(...compareRank);
        const maxRank = Math.max(...rank);
        const arr = data.map((item) => [
          item.rank,
          item.compareRank,
          item.name,
        ]);
        this.chartOptions = {
          tooltip: {
            axisPointer: {
              type: "shadow",
            },
            formatter: function (param) {
              return `${param.data[2]}:${param.data[0]},${param.data[1]}`;
            },
          },
          toolbox: {
            feature: {
              dataZoom: {},
            },
          },
          dataset: [
            {
              source: arr,
            },
            {
              transform: {
                type: "ecStat:regression",
                config: { method: "polynomial" },
              },
            },
          ],
          xAxis: {
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
            axisLine: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            splitLine: { show: false },
          },
          yAxis: {
            axisLine: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            axisTick: {
              show: true,
              lineStyle: { color: "rgba(0, 0, 0, 0.3)" },
            },
            splitLine: { show: false },
          },
          series: [
            {
              symbolSize: 10,
              name: "考试排名对比",
              type: "scatter",
              datasetIndex: 0,
              markLine: {
                lineStyle: {
                  type: "solid",
                  color: "#FF9776",
                },
                tooltip: {
                  show: false,
                },
                data: [
                  [
                    {
                      coord: [0, 0],
                      symbol: "none",
                    },
                    {
                      coord: [maxRank, maxCompareRank],
                      symbol: "none",
                    },
                  ],
                ],
              },
              label: {
                show: true,
                position: "top",
                color: "inherit",
                fontSize: 9,
                formatter: function (params) {
                  return `${params.data[2]}`;
                },
              },
            },
          ],
        };
        this.chartKey = Math.random();
      } else {
        this.chartOptions = null;
      }
    },
    async getTopOrBackward() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getUnionToporbackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          subjectId: this.subjectId,
          classNum: this.classNum,
          isAllClass: 0,
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await topOrBackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          subjectId: this.subjectId,
          classNum: this.classNum,
          isAllClass: 0,
          id: this.$route.query.id,
        });
      }

      this.columns = [...this.topCol];
      const subject = this.subjectList.find(
        (item) => item.subjectId === this.subjectId
      );
      this.columns[3].title = subject.subjectName;
      this.columns[4].title = subject.subjectName + "排名";
      this.tableData = res.data.data;
      // this.defineOptions = {
      //   xAxis: {
      //     axisTick: {
      //       show: false,
      //     },
      //     type: "category",
      //     data: [...this.tableData.map((item) => item.name)],
      //   },
      // };
      // this.series = [
      //   {
      //     type: "bar",
      //     name: this.type == 2 ? "拔尖生" : "后进生",
      //     data: [...this.tableData.map((item) => item.score)],
      //     barMaxWidth: 40,
      //     label: {
      //       show: true,
      //       position: "top",
      //       color: "inherit",
      //     },
      //   },
      // ];
      // this.chartKey = Math.random();
    },
    async trackStudent() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getUnionTrack({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          classNum: this.classNum,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await trackStudent({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          classNum: this.classNum,
        });
      }

      this.columns = [...this.trackCol];
      const subject = this.subjectList.find(
        (item) => item.subjectId === this.subjectId
      );
      this.columns[4].title = subject.subjectName;
      this.columns[5].title = subject.subjectName + "排名";
      this.tableData = res.data.data;
      // this.defineOptions = {
      //   xAxis: {
      //     axisTick: {
      //       show: false,
      //     },
      //     type: "category",
      //     data: [...res.data.data.map((item) => item.name)],
      //   },
      // };
      // this.series = [
      //   {
      //     type: "bar",
      //     name: "跟踪生",
      //     data: [...res.data.data.map((item) => item.score)],
      //     barMaxWidth: 40,
      //     label: {
      //       show: true,
      //       position: "top",
      //       color: "inherit",
      //     },
      //   },
      // ];
      // this.chartKey = Math.random();
    },
    classNumChange(val) {
      this.checkClassNum = val;
      this.typeChange();
    },
    async getstatcomparelistData() {
      const res = await getstatcomparelist({
        statId: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.selectOptions = res.data.data;
      if (res.data.data.length) {
        this.wave.compareStatId = res.data.data[0].id;
        this.getWaveData();
      }
    },
    formatterCriticalData(data) {
      let arr = data.map((item, index) => {
        if (index < data.length - 1) {
          return {
            riskList: [...item.riskList],
            potential: [...data[index + 1].potential],
          };
        }
      });
      return arr.filter((item) => item);
    },
    async getCriticalData() {
      const res = await getSubjectCritical({
        subjectId: this.subjectId,
        id: this.$route.query.id,
        classNum: this.classNum,
      });
      if (res.data.data.classVO) {
        let levels = res.data.data.classVO.levels;
        this.critialColumns = [
          {
            title: "班级",
            width: 85,
            align: "center",
            dataIndex: "classNum",
          },
          ...levels.map((item, index) => ({
            title: item.level,
            align: "center",
            children: [
              {
                title: "潜力生比率",
                align: "center",
                dataIndex: "potentialProportion" + index,
                width: 120,
                customRender: (text, record) =>
                  record.levels[index].potentialProportion + "%",
              },
              {
                title: "风险生比率",
                align: "center",
                width: 120,
                dataIndex: "riskProportion" + index,
                customRender: (text, record) =>
                  record.levels[index].riskProportion + "%",
              },
            ],
          })),
        ];
        this.crititalData = [res.data.data.classVO];
      }
      if (res.data.data.levels.length) {
        this.tableData = res.data.data.levels;
      }
      this.chartKey = Math.random();
    },
    typeChange() {
      this.series = [];
      this.defineOptions = null;
      this.tableData = [];
      this.columns = [];
      if (this.type === 1) {
        if (this.$parent.topData.isSchoolDisplayReportClassRank == 1) {
          this.columns = [...this.waveCol];
        } else {
          this.columns = this.waveCol.filter(
            (item) => !["本次校排名", "对比考试排名"].includes(item.title)
          );
        }
        this.getstatcomparelistData();
      } else if (this.type === 2) {
        this.topOrBackward = 20;
        this.getTopOrBackward();
      } else if (this.type === 3) {
        this.topOrBackward = 20;
        this.getTopOrBackward();
      } else if (this.type === 4) {
        this.trackStudent();
      } else if (this.type === 5) {
        this.getCriticalData();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTop) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upStatId) {
        this.$message({
          showClose: false,
          message: "请选择对比考试",
          type: "error",
        });
      } else {
        this.getTopOrBackward();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}

.charts {
  margin-bottom: 18px;

  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;

    span {
      margin: 0 8px;
    }

    div {
      margin-right: 18px;
    }
  }
}

.classNum {
  margin-bottom: 18px;
}

.subjectShit {
  color: #f56c6c;
}

// .critical {
//   display: flex;
//   align-items: flex-start;
// }
.nameList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  .student {
    color: #2474ed;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
</style>

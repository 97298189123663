import request from "@/core/services/axios";
export const getrenkejiaoshireporttopinfo = (params) => {
  // 获取任课教师报告顶部数据-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrenkejiaoshireporttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetrenkejiaoshireportscoredistribution = (params) => {
  // 任课教师报告-成绩分布-分数分布-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrenkejiaoshireportscoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetrkjsreportclasscompetitive = (params) => {
  // 任课教师报告-班级竞争力-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportclasscompetitive`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionWave = (params) => {
  // 任课教师报告-重点关注学生-波动生-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/wave`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionToporbackward = (params) => {
  // 任课教师报告-重点关注学生-拔尖生-后进生-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/toporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionTrack = (params) => {
  // 任课教师报告-重点关注学生-跟踪生-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/track`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionrkjsreportdevelopanalysis = (params) => {
  // 任课教师报告-发展性分析-联考新高考
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportdevelopanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionQuestion = (params) => {
  // 任课教师报告-试卷分析-应答情况分析-题目
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportpaperanaly/question`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getUnionStudent = (params) => {
  // 任课教师报告-试卷分析-学生应答反馈
  return request({
    url: `/report/statunionnewgaokaorenkejiaoshireport/getrkjsreportpaperanaly/student`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};

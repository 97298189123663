export const ratioCol = [
  {
    dataIndex: "classNum",
    title: "班级",
    align: "center",
  },
  {
    dataIndex: "statTotal",
    title: "统计人数",
    align: "center",
  },
];
export const topSectionCol = [
  {
    title: "班级",
    dataIndex: "classNum",
    align: "center",
    width: 100,
  },
  {
    title: "统计人数",
    dataIndex: "statTotal",
    align: "center",
    width: 100,
  },
];
export const moreOptions = [
  {
    label: "方差",
    value: "variance",
  },
  {
    label: "差异系数",
    value: "coefficientOfVariation",
  },
  {
    label: "得分率",
    value: "scoreRate",
  },
  {
    label: "超均率",
    value: "overAverageRate",
  },
  {
    label: "区分度",
    value: "discrimination",
  },
  {
    label: "最高分",
    value: "highestScore",
  },
  {
    label: "最低分",
    value: "lowestScore",
  },
  {
    label: "标准差",
    value: "standardDeviation",
  },
];

<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><a-icon type="left" /> 返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <exportBtn
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 0,
          classNum: classNum,
          subjectId: subjectId,
        }"
        btn-type="primary"
        text="导出全部报表"
      >
        <div class="model-title">任课教师报告</div>
      </exportBtn>
      <div class="subjects">
        <a-select
          v-model="subjectId"
          not-found-content="暂无数据"
          style="width: 120px"
          @change="subjectChange"
        >
          <a-select-option
            v-for="(item, index) in subjectList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="a-tabs">
        <a-tabs v-model="classNum" @change="getClassList">
          <a-tab-pane
            v-for="item in classNumList"
            :key="item.name"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
      <!-- <div class="dash">
        <div class="box">
          <div class="box-item">
            <div class="left">
              <div class="num">{{ topData.classAverageOri }}</div>
              <div class="title">平均分NO.1</div>
            </div>
            <div class="right">
              年级：{{ topData.gradeAverageOri }}
              <a-icon type="check" style="color: #2474ed" />
            </div>
          </div>
        </div>
        <div v-if="![1, 2, 3, 4, 8].includes(subjectId)" class="box">
          <div class="box-item">
            <div class="left">
              <div class="num">{{ topData.classAverage }}</div>
              <div class="title">平均分NO.1</div>
            </div>
            <div class="right">
              年级：{{ topData.gradeAverage }}
              <a-icon type="check" style="color: #2474ed" />
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <template v-if="classNum && subjectId && topData.year">
      <brach
        id="branh"
        :key="'brach' + componentsKey"
        :class-num="classNum"
        :subject-id="subjectId"
        :top-data="topData"
      />
      <competition
        id="competition"
        :key="'competition' + componentsKey"
        :class-num="classNum"
        :subject-id="subjectId"
      />
      <student
        id="student"
        :key="'student' + componentsKey"
        :class-num="classNum"
        :subject-id="subjectId"
      />
      <development
        id="development"
        :key="'development' + componentsKey"
        :class-num="classNum"
        :subject-id="subjectId"
      />
      <paper
        id="paper"
        :key="'paper' + componentsKey"
        :class-num="classNum"
        :subject-id="subjectId"
        :top-data="topData"
      />
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import brach from "./branch";
import competition from "./competition";
import student from "./student";
import development from "./development";
import paper from "./paper";
import { getStore } from "@/core/util/store";
import { exportTeacherReportNew } from "@/core/api/academic/teacher";
import {
  getstatsubjectoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { getTopData } from "@/core/api/newExamAcademic/teacher";
import pageNav from "@/views/academic/components/pageNav.vue";
import { getrenkejiaoshireporttopinfo } from "@/core/api/newExamAcademic/union/teacher";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  components: {
    brach,
    competition,
    student,
    development,
    paper,
    pageNav,
    exportBtn,
  },
  data() {
    return {
      subjectId: 0,
      topData: {
        type: 99,
      },
      requestFunc: exportTeacherReportNew,
      subjectList: [],
      classNumList: [],
      classNum: "",
      schoolName: "",
      componentsKey: Math.random(),
      menuList: [
        {
          id: "branh",
          name: "成绩分布",
        },
        {
          id: "competition",
          name: "班级竞争力",
        },
        {
          id: "student",
          name: "重点关注学生",
        },
        {
          id: "development",
          name: "发展性分析",
        },
        {
          id: "paper",
          name: "试卷分析",
        },
      ],
    };
  },
  mounted() {
    this.schoolName = getStore({ name: "userInfo" }).schoolName;
    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
    this.getSubjectList();
  },
  methods: {
    getClassList() {
      this.componentsKey = Math.random();
      this.getData();
    },
    subjectChange() {
      this.getClassNum();
    },
    async getData() {
      this.topData = {};
      let res;
      if (this.$route.query.examType == 1) {
        res = await getrenkejiaoshireporttopinfo({
          id: this.$route.query.id,
          classNum: this.classNum,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTopData({
          id: this.$route.query.id,
          classNum: this.classNum,
          subjectId: this.subjectId,
        });
      }

      this.topData = res.data.data;
    },
    async getClassNum() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 4,
      });
      this.classNumList = res.data.data;
      if (res.data.data.length) {
        this.classNum = res.data.data[0].name;
        this.getData();
      } else {
        this.classNum = null;
      }
      this.componentsKey = Math.random();
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        classNum: this.classNum,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
        requestPage: 4,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getClassNum();
      } else {
        this.subjectId = null;
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../new-exam.module.scss";
.a-tabs {
  width: 100%;
}
::v-deep .ant-tabs-ink-bar {
  display: none !important;
}
::v-deep .ant-tabs-bar {
  border-bottom: none;
}
::v-deep .ant-tabs-tab {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}
::v-deep .ant-tabs-tab-active {
  border: 1px solid #2474ed;
  text-shadow: 0 0 0.25px currentColor;
  border-radius: 16px;
  color: #2474ed;
  background: #f3f8ff;
}
::v-deep .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.subjects {
  margin-bottom: 18px;
  text-align: center;
}
.box {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .box-item {
    box-sizing: border-box;
    background: #f3f8ff;
    border-radius: 8px;
    padding: 16px 24px 15px 38px;
    display: flex;
    align-items: flex-start;
    .left {
      margin-right: 46px;
      .num {
        color: #2474ed;
        font-size: 18px;
      }
      .title {
        color: #3e4551;
        font-size: 14px;
        margin-top: 12px;
      }
    }
    .right {
      color: #3e4551;
      font-size: 14px;
    }
  }
}
.dash {
  display: flex;
  align-items: center;
  margin-top: 18px;
  .box {
    margin-right: 8px;
  }
}
</style>

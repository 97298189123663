<template>
  <div ref="competitive" v-lazyChart="{ fn: getData }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 1,
        classNum: classNum,
        subjectId: subjectId,
      }"
    >
      <div class="title">班级竞争力</div>
    </exportBtn>
    <div class="filter">
      <span></span>
      <a-radio-group
        v-model="category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button v-if="$route.query.examType != 1" :value="2">
          四分位分析
        </a-radio-button>
        <a-radio-button v-if="ratios.length" :value="3"> 比 率 </a-radio-button>
        <a-radio-button v-if="topSections.length" :value="4">
          前 N 名
        </a-radio-button>
        <a-radio-button :value="5"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="filter">
      <div>
        数据源：
        <a-radio-group v-model="dataSource" name="radioGroup" @change="getData">
          <a-radio :value="1"> 原始分 </a-radio>
          <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="2">
            赋分
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="select">
      <a-select
        v-if="[3, 4, 5].includes(category)"
        v-model="value"
        style="width: 220px"
        not-found-content="暂无数据"
        placeholder="请选择"
        @change="handleChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <template v-if="defineOptions">
      <chart
        v-if="category !== 2"
        :key="tableDataKey"
        :define-options="defineOptions"
        :series="series"
        :data-zoom="20"
      />
      <box-plot
        v-else
        :key="'plot' + tableDataKey"
        show-tooltip
        :define-options="defineOptions"
        :x-axis="BoxPlotXAxis"
      ></box-plot>
    </template>
    <no-data v-else />

    <exTable
      :key="'exTable' + tableDataKey"
      :columns="column"
      style="margin: 18px 0"
      :data-source="tableData"
      :col-span-num="colSpanNum"
    />
    <div class="tip">
      <div class="wrapper">
        <div>注：</div>
        <div>
          <template v-if="category === 1">
            <div>
              <span v-if="![1, 2, 3, 4, 8].includes(subjectId)"
                >除赋分均分外，</span
              >
              以上数据结果均以{{
                dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>以上数据均不包括不参与统计的考生成绩</div>
          </template>
          <template v-if="category === 2">
            <div>
              以上数据结果均以{{
                dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>以上数据均不包括不参与统计的考生成绩</div>
          </template>
          <template v-if="category === 3">
            <div>
              以上数据结果均以{{
                dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>以上数据均不包括不参与统计的考生成绩</div>
          </template>
          <template v-if="category === 4">
            <div>
              以上数据结果均以{{
                dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>以上数据均不包括不参与统计的考生成绩</div>
          </template>
          <template v-if="category == 5">
            <div>
              以上数据结果采用{{
                dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>
              方差
              各个数据与平均数之差的平方的和的平均数，用来衡量一批数据的波动大小(即这批数据偏离平均数的大小)
            </div>
            <div>
              标准差 方差的算术平方根
              表示一组数值的离散程度，标准差越大，表示该组数值与均值的偏差越大
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "@/views/newExamAcademic/components/charts.vue";
import BoxPlot from "@/views/newExamAcademic/components/BoxPlot.vue";
import exTable from "../../components/exTable.vue";
import { getrkjsreportclasscompetitive } from "@/core/api/newExamAcademic/teacher";
import { exportTeacherReportNew } from "@/core/api/academic/teacher";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { unionGetrkjsreportclasscompetitive } from "@/core/api/newExamAcademic/union/teacher";
import { ratioCol, topSectionCol, moreOptions } from "./column";
export default {
  components: {
    chart,
    BoxPlot,
    exTable,
    exportBtn,
  },
  props: {
    classNum: {
      type: [String, Number],
      default: "",
    },
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      avgCol: [
        {
          title: "排名",
          dataIndex: "rank",
          align: "center",
        },
        {
          title: "班级",
          dataIndex: "classNum",
          align: "center",
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
        },
        {
          title: "平均分",
          dataIndex: "averageScoreOri",
          align: "center",
        },

        {
          title: "赋分均分",
          dataIndex: "averageScore",
          align: "center",
        },
        {
          title: "中位数",
          dataIndex: "medianOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 1 ? record.medianOri : record.median,
        },
        {
          title: "排名前27%均分",
          dataIndex: "top27AverageScoreOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.top27AverageScoreOri
              : record.top27AverageScore,
        },
        {
          title: "排名后27%均分",
          dataIndex: "after27AverageScoreOri",
          align: "center",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.after27AverageScoreOri
              : record.after27AverageScore,
        },
        {
          title: "区分度",
          dataIndex: "discriminationOri",
          align: "center",
          customRender: (text, record) =>
            record[
              this.dataSource == 1 ? "discriminationOri" : "discrimination"
            ].toFixed(2),
        },
      ],
      quartileCol: [
        {
          title: "班级",
          dataIndex: "classNum",
          align: "center",
          width: 75,
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
          width: 100,
        },
        {
          title: "中位数",
          dataIndex: "medianOri",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            this.dataSource == 1 ? record.medianOri : record.median,
        },

        {
          title: "上限",
          dataIndex: "upperWhiskerOri",
          align: "center",
          width: 75,
          customRender: (text, record) =>
            this.dataSource == 1 ? record.upperWhiskerOri : record.upperWhisker,
        },
        {
          title: "最高分",
          dataIndex: "highestScoreOri",
          align: "center",
          width: 85,
          customRender: (text, record) =>
            this.dataSource == 1 ? record.highestScoreOri : record.highestScore,
        },

        {
          title: "下限",
          dataIndex: "lowerWhiskerOri",
          align: "center",
          width: 75,
        },
        {
          title: "最低分",
          dataIndex: "lowestScoreOri",
          align: "center",
          width: 85,
        },

        {
          title: "四分位间距",
          dataIndex: "interQuartileRangeOri",
          align: "center",
          width: 115,
        },
        {
          title: "全距/极差",
          dataIndex: "rangeScoreOri",
          align: "center",
          width: 115,
        },
      ],
      requestFunc: exportTeacherReportNew,
      classNumsList: [],
      classNumChecked: null,
      category: 1,
      defineOptions: {},
      series: [],
      chartKey: Math.random(),
      BoxPlotXAxis: ["11班", "12班", "13班", "14班", "15班", "16班"],
      type: 2,
      value: "variance",
      column: [],
      tableData: [],
      tableDataKey: Math.random(),
      dataSource: 1,
      colSpanNum: 1,
      selectOptions: [],
      moreCol: [
        {
          dataIndex: "classNum",
          align: "center",
          title: "班级",
        },
        {
          dataIndex: "statTotal",
          align: "center",
          title: "统计人数",
        },

        {
          dataIndex: "highestScoreOri",
          align: "center",
          title: "最高分",
          customRender: (text, record) =>
            this.dataSource == 1 ? record.highestScoreOri : record.highestScore,
        },
        {
          dataIndex: "lowestScoreOri",
          align: "center",
          title: "最低分",
          customRender: (text, record) =>
            this.dataSource == 1 ? record.lowestScoreOri : record.lowestScore,
        },
        {
          dataIndex: "standardDeviationOri",
          align: "center",
          title: "标准差",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.standardDeviationOri
              : record.standardDeviation,
        },
        {
          dataIndex: "varianceOri",
          align: "center",
          title: "方差",
          customRender: (text, record) =>
            this.dataSource == 1 ? record.varianceOri : record.variance,
        },
        {
          dataIndex: "coefficientOfVariationOri",
          align: "center",
          title: "差异系数",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.coefficientOfVariationOri
              : record.coefficientOfVariation,
        },
        {
          dataIndex: "discriminationOri",
          align: "center",
          title: "区分度",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.discriminationOri.toFixed(2)
              : record.discrimination.toFixed(2),
        },
        {
          dataIndex: "scoreRateOri",
          align: "center",
          title: "得分率",
          customRender: (text, record) =>
            this.dataSource == 1 ? record.scoreRateOri : record.scoreRate,
        },
        {
          dataIndex: "overAverageRateOri",
          align: "center",
          title: "超均率",
          customRender: (text, record) =>
            this.dataSource == 1
              ? record.overAverageRateOri
              : record.overAverageRate,
        },
      ],
      ratios: [],
      topSections: [],
    };
  },
  mounted() {
    if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
      this.dataSource = 1;
    }
  },
  methods: {
    handleChange() {
      if (this.category == 3) {
        this.setRatio();
      } else if (this.category == 4) {
        this.setTop();
      } else {
        this.setMore();
      }
    },
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetrkjsreportclasscompetitive({
          id: this.$route.query.id,
          classNum: this.classNum,
          subjectId: this.subjectId,
          dataSource: this.dataSource,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getrkjsreportclasscompetitive({
          id: this.$route.query.id,
          classNum: this.classNum,
          subjectId: this.subjectId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore != 1) {
        this.tableData = res.data.data.filter(
          (item) => item.classNum != "整体"
        );
      } else {
        this.tableData = res.data.data;
      }
      if (this.tableData.length) {
        this.ratios = res.data.data[0].ratios;
        this.topSections = res.data.data[0].topSections;
      }
      this.categoryChange();
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.competitive);
        });
      }
    },
    categoryChange() {
      this.selectOptions = [];
      this.value = null;

      this.defineOptions = null;
      let run = {
        1: () =>
          this.$route.query.examType == 1 ? this.setUnionAvg() : this.setAvg(),
        2: () => this.setQuartile(),
        3: () => {
          if (this.tableData.length) {
            let ratios = [];
            ratios = this.tableData[0].ratios || [];
            this.column = [
              ...topSectionCol,
              ...ratios.map((item, index) => ({
                title: item.name,
                align: "center",
                dataIndex: "ratios" + index,
                customRender: (text, record) =>
                  record.ratios[index][
                    "proportion" + (this.dataSource == 1 ? "Ori" : "")
                  ] + "%",
              })),
            ];
            if (ratios.length) {
              this.value = ratios[0].name;
            }
            this.selectOptions = ratios.map((item) => ({
              label: item.name,
              value: item.name,
            }));
          }
          return this.setRatio();
        },
        4: () => {
          if (this.tableData.length) {
            let topSections = this.tableData[0].topSections || [];
            this.column = [
              ...topSectionCol,
              ...topSections.map((item, index) => ({
                title: item.name,
                align: "center",
                dataIndex: "topSections" + index,
                customRender: (text, record) =>
                  record.topSections[index][
                    "proportion" + (this.dataSource == 1 ? "Ori" : "")
                  ] + "%",
              })),
            ];

            if (topSections.length) {
              this.value = topSections[0].name;
            }
            this.tableDataKey = Math.random();
            this.selectOptions = topSections.map((item) => ({
              label: item.name,
              value: item.name,
            }));
          }
          if (this.selectOptions.length) {
            return this.setTop();
          }
        },
        5: () => {
          this.value = "variance";
          return this.setMore();
        },
      };
      run[this.category]();
    },
    dataSourceChange() {
      this.categoryChange();
    },
    setAvg() {
      this.colSpanNum = 1;
      this.column = [...this.avgCol];
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.column.splice(4, 1);
      }
      let data = this.tableData.filter((item) => item.classNum !== "年级");
      let grade = this.tableData.find((item) => item.classNum == "年级");
      this.defineOptions = {
        xAxis: {
          type: "category",
          data: [...data.map((item) => item.classNum)],
          axisTick: {
            show: false,
          },
        },
      };
      this.series = [
        {
          type: "bar",
          name: "班级",
          data: [
            ...data.map(
              (item) =>
                item["averageScore" + (this.dataSource === 1 ? "Ori" : "")]
            ),
          ],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },
        {
          type: "line",
          name: "年级",
          color: "#FF9268",
          data: [
            ...data.map(
              () => grade["averageScore" + (this.dataSource === 1 ? "Ori" : "")]
            ),
          ],
        },
      ];
      this.tableDataKey = Math.random();
    },
    setUnionAvg() {
      this.colSpanNum = 1;
      const data = this.tableData.filter(
        (item) => !["整体", "学校"].includes(item.classNum)
      );
      const school = this.tableData.find((item) => item.classNum == "学校");
      const total = this.tableData.find((item) => item.classNum == "整体");
      this.defineOptions = {
        xAxis: {
          type: "category",
          data: [...data.map((item) => item.classNum)],
          axisTick: {
            show: false,
          },
        },
      };
      this.series = [
        {
          type: "bar",
          name: "班级",
          data: [
            ...data.map(
              (item) =>
                item["averageScore" + (this.dataSource === 1 ? "Ori" : "")]
            ),
          ],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },
        {
          type: "line",
          name: "学校",
          color: "#FF9268",
          data: [
            ...data.map(
              () =>
                school["averageScore" + (this.dataSource === 1 ? "Ori" : "")]
            ),
          ],
        },
      ];
      if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
        this.series.push({
          type: "line",
          name: "整体",
          color: "#FF9268",
          data: [
            ...data.map(
              () => total["averageScore" + (this.dataSource === 1 ? "Ori" : "")]
            ),
          ],
        });
      }
      this.column = [
        ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
          ? [
              {
                title: "整体排名",
                dataIndex: "rank",
                align: "center",
                customRender: (text, record) => {
                  if (!["整体", "学校"].includes(record.classNum)) {
                    return this.dataSource == 1 ? record.rankOri : record.rank;
                  }
                  return "-";
                },
              },
            ]
          : []),
        {
          title: "班级",
          dataIndex: "classNum",
          align: "center",
        },
        {
          title: "统计人数",
          dataIndex: "statTotal",
          align: "center",
        },
        {
          title: "平均分",
          dataIndex: this.dataSource == 1 ? "averageScoreOri" : "averageScore",
          align: "center",
        },
        {
          title: "中位数",
          dataIndex: this.dataSource == 1 ? "medianOri" : "median",
          align: "center",
        },
        {
          title: "前27%均分",
          dataIndex:
            this.dataSource == 1 ? "top27AverageScoreOri" : "top27AverageScore",
          align: "center",
        },
        {
          title: "后27%均分",
          dataIndex:
            this.dataSource == 1
              ? "after27AverageScoreOri"
              : "after27AverageScore",
          align: "center",
        },
        {
          title: "区分度",
          dataIndex:
            this.dataSource == 1 ? "discriminationOri" : "discrimination",
          align: "center",
        },
      ];
      this.tableDataKey = Math.random();
    },
    setQuartile() {
      this.colSpanNum = 1;
      let data = this.tableData.filter((item) => item.classNum !== "年级");
      let grade = this.tableData.find((item) => item.classNum == "年级");
      this.BoxPlotXAxis = [...data.map((item) => item.classNum)];
      this.defineOptions = {
        legend: {
          data: ["年级最高分", "年级最低分", "年级中位数"],
        },
        dataset: [
          {
            source: [
              ...data.map((item) => {
                return [
                  item.lowerWhiskerOri,
                  item.lowerQuartileOri,
                  item.medianOri,
                  item.upperQuartileOri,
                  item.upperWhiskerOri,
                ];
              }),
            ],
          },
          {
            transform: {
              type: "boxplot",
              config: {},
            },
          },
          {
            fromDatasetIndex: 1,
            fromTransformResult: 1,
          },
        ],
        series: [
          {
            name: this.classNum,
            type: "boxplot",
            datasetIndex: 1,
            itemStyle: {
              color: "#7AB5EF",
            },
            boxWidth: [20, 20],
          },
          {
            name: "偏移值",
            type: "scatter",
            datasetIndex: 2,
            itemStyle: {
              color: "#4589EF",
            },
            symbolSize: 4,
          },
          {
            type: "line",
            name: "年级最高分",
            lineStyle: {
              type: "dashed",
            },
            color: "#FF0000",
            data: [...data.map(() => grade.highestScoreOri)],
          },
          {
            type: "line",
            name: "年级最低分",
            lineStyle: {
              type: "dashed",
            },
            data: [...data.map(() => grade.lowestScoreOri)],
            color: "#ED6024",
          },
          {
            type: "line",
            name: "年级中位数",
            lineStyle: {
              type: "dashed",
            },
            data: [...data.map(() => grade.medianOri)],
            color: "#FFAB00",
          },
        ],
      };
      this.column = [...this.quartileCol];
      this.tableDataKey = Math.random();
    },
    setRatio() {
      this.colSpanNum = 1;
      let ratios = [];
      if (this.tableData.length) {
        ratios = this.tableData[0].ratios;

        this.column = [
          ...(this.$parent.topData.isSchoolDisplayReportClassRank == 1
            ? [
                {
                  title: "整体排名",
                  dataIndex: "rank",
                  align: "center",
                  customRender: (text, record) => {
                    if (!["整体", "学校"].includes(record.classNum)) {
                      return record.rank;
                    }
                    return "-";
                  },
                },
              ]
            : []),
          ...ratioCol,

          ...ratios.map((item, index) => ({
            title: item.name,
            align: "center",
            dataIndex: "ratios" + index,
            customRender: (text, record) =>
              record.ratios[index][
                "proportion" + (this.dataSource == 1 ? "Ori" : "")
              ] + "%",
          })),
        ];
        const data = this.tableData.filter(
          (item) => !["年级", "学校", "整体"].includes(item.classNum)
        );
        const grade = this.tableData.find((item) =>
          ["年级", "学校"].includes(item.classNum)
        );
        this.defineOptions = {
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [...data.map((item) => item.classNum)],
          },
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        };
        let barData = data.map((item) => {
          let itemData = item.ratios.find((it) => it.name === this.value);
          return itemData[
            this.dataSource == 1 ? "proportionOri" : "proportion"
          ];
        });
        let gradeData = grade.ratios.find((item) => item.name === this.value);
        this.series = [
          {
            type: "bar",
            barMaxWidth: 40,
            stack: "ratios",
            name: this.value,
            data: [...barData],
            label: {
              position: "top",
              color: "inherit",
              show: true,
            },
          },
          {
            type: "line",
            name: this.$route.query.examType == 1 ? "学校" : "年级",
            color: "#FF9268",
            data: [
              ...data.map(
                () =>
                  gradeData[
                    this.dataSource == 1 ? "proportionOri" : "proportion"
                  ]
              ),
            ],
          },
        ];
        if (
          this.$route.query.examType == 1 &&
          this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
        ) {
          const total = this.tableData.find((item) => item.classNum == "整体");
          this.series.push({
            type: "line",
            name: "整体",
            data: [
              ...data.map(
                () =>
                  total.ratios.find((item) => item.name === this.value)[
                    this.dataSource == 1 ? "totalOri" : "total"
                  ]
              ),
            ],
          });
        }
      }
      this.tableDataKey = Math.random();
    },
    setTop() {
      this.colSpanNum = 1;
      if (this.tableData.length) {
        const data = this.tableData.filter(
          (item) => !["年级", "学校", "整体"].includes(item.classNum)
        );
        const grade = this.tableData.find((item) =>
          ["年级", "学校"].includes(item.classNum)
        );
        this.defineOptions = {
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          xAxis: {
            axisTick: {
              show: false,
            },
            type: "category",
            data: [...data.map((item) => item.classNum)],
          },
        };

        let barData = data.map((item) => {
          let itemData = item.topSections.find((it) => it.name === this.value);
          return itemData[
            this.dataSource == 1 ? "proportionOri" : "proportion"
          ];
        });
        let gradeData = grade.topSections.find(
          (item) => item.name === this.value
        );
        this.series = [
          {
            type: "bar",
            barMaxWidth: 40,
            stack: "topSections",
            name: this.value,
            data: [...barData],
            label: {
              position: "top",
              color: "inherit",
              show: true,
            },
          },
          {
            type: "line",
            name: "年级",
            color: "#FF9268",
            data: [
              ...data.map(
                () =>
                  gradeData[
                    this.dataSource == 1 ? "proportionOri" : "proportion"
                  ]
              ),
            ],
          },
        ];
        if (
          this.$route.query.examType == 1 &&
          this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
        ) {
          const total = this.tableData.find((item) => item.classNum == "整体");
          this.series.push({
            type: "line",
            name: "整体",

            data: [
              ...data.map(() =>
                total.topSections.find((item) => item.name === this.value)
              ),
            ],
          });
        }
      }
      this.tableDataKey = Math.random();
    },
    titleCase(str) {
      var newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    setMore() {
      this.selectOptions = [...moreOptions];
      const data = this.tableData.filter(
        (item) => !["年级", "学校", "整体"].includes(item.classNum)
      );
      const grade = this.tableData.find((item) =>
        ["年级", "学校"].includes(item.classNum)
      );
      this.colSpanNum = 1;
      this.column = [...this.moreCol];
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...data.map((item) => item.classNum)],
        },
      };
      this.series = [
        {
          type: "bar",
          name: "班级",
          color: "#619AF1",
          data: [
            ...data.map((item) => {
              if (this.value === "discrimination") {
                return (
                  item[
                    this.value + (this.dataSource == 1 ? "Ori" : "")
                  ].toFixed(2) || 0
                );
              }
              return (
                item[this.value + (this.dataSource == 1 ? "Ori" : "")] || 0
              );
            }),
          ],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },

        {
          type: "line",
          name: "年级",
          color: "#FF9268",
          data: [
            ...data.map(() => {
              if (this.value === "discrimination") {
                return (
                  grade[
                    this.value + (this.dataSource == 1 ? "Ori" : "")
                  ].toFixed(2) || 0
                );
              }
              return (
                grade[this.value + (this.dataSource == 1 ? "Ori" : "")] || 0
              );
            }),
          ],
        },
      ];
      if (this.$route.query.examType == 1) {
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          const total = this.tableData.find((item) => item.classNum == "整体");
          this.series.push({
            type: "line",
            name: "整体",

            data: [
              ...data.map(() => {
                if (this.value === "discrimination") {
                  return (
                    total[
                      this.value + (this.dataSource == 1 ? "Ori" : "")
                    ].toFixed(2) || 0
                  );
                }
                return (
                  total[this.value + (this.dataSource == 1 ? "Ori" : "")] || 0
                );
              }),
            ],
          });
        }
      }

      this.tableDataKey = Math.random();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../new-exam.module.scss";

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
</style>

<template>
  <div ref="development" v-lazyChart="{ fn: getData }" class="container">
    <div class="title">发展性分析</div>
    <examTransformVue
      :stat-id="$route.query.id"
      :exam-arr="examArr"
      :class-num="classNum"
      :subject-id="subjectId"
      style="margin-bottom: 18px"
      new-exam
      @submit="getExamId"
    />
    <div class="operate">
      <div>
        <a-select
          v-if="type !== '1' && selectOptions.length"
          v-model="value"
          not-found-content="暂无数据"
          style="width: 200px"
          @change="handleChange"
        >
          <a-select-option
            v-for="(item, index) in selectOptions"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button value="1"> 超均率 </a-radio-button>
        <!-- <a-radio-button value="2"> 比率 </a-radio-button> -->
        <a-radio-button v-if="topSections.length" value="3">
          前N名
        </a-radio-button>
      </a-radio-group>
    </div>
    <chart
      v-if="defineOptions"
      :key="chartKey"
      :define-options="defineOptions"
      :series="series"
    />
    <no-data v-else />
    <div class="tip">
      <div class="intent">注：</div>
      <div>
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examTransformVue from "../../components/examTransform.vue";
import chart from "../../components/charts.vue";
import { getDevelopment } from "@/core/api/newExamAcademic/teacher";
import { getUnionrkjsreportdevelopanalysis } from "@/core/api/newExamAcademic/union/teacher";
export default {
  name: "",
  components: {
    examTransformVue,
    chart,
  },
  props: {
    classNum: {
      type: [String, Number],
      default: "",
    },
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      defineOptions: null,
      series: [],
      type: "1",
      examList: [],
      visible: false,
      confirmLoading: false,
      chartKey: Math.random(),
      examTypes: undefined,
      examArr: [],
      contrastStatId: "",
      chartData: null,
      selectOptions: [],
      compareIds: [],
      value: "",
      topSections: [],
    };
  },
  created() {},
  methods: {
    getExamId(val) {
      this.contrastStatId = val.toString();
      this.getData();
    },
    handleChange() {
      if (this.type === "2") {
        this.setRatio();
      } else {
        this.setTopSections();
      }
    },
    typeChange() {
      this.defineOptions = null;
      this.selectOptions = [];
      if (this.type === "1") {
        this.setOverAverageRate();
      } else if (this.type === "2") {
        if (this.chartData.ratios.length) {
          this.value = this.chartData.ratios[0].id;
          this.selectOptions = this.chartData.ratios;
        }
        this.setRatio();
      } else {
        if (this.chartData.topSections.length) {
          this.value = this.chartData.topSections[0].id;
          this.selectOptions = this.chartData.topSections;
        }
        this.setTopSections();
      }
    },
    async getData(loadingInstance, io) {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getUnionrkjsreportdevelopanalysis({
          subjectId: this.subjectId,
          id: this.$route.query.id,
          classNum: this.classNum,
          contrastStatId: this.contrastStatId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getDevelopment({
          subjectId: this.subjectId,
          id: this.$route.query.id,
          classNum: this.classNum,
          contrastStatId: this.contrastStatId,
        }).catch(() => {
          this.closeLoading(loadingInstance, io);
        });
      }

      this.chartData = res.data.data;
      this.examArr = res.data.data.stats;
      this.topSections = res.data.data.topSections;
      this.typeChange();
      this.closeLoading(loadingInstance, io);
    },
    setOverAverageRate() {
      if (this.chartData.stats.length) {
        this.defineOptions = {
          legend: { itemGap: 18, type: "scroll" },
          grid: {
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [
              ...this.chartData.stats.map((item) => item.name + item.examDate),
            ],
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
          },
        };
        this.series = [
          {
            type: "line",
            name: "超均率",
            data: [
              ...this.chartData.stats.map(
                (item) => item.classData.overAverageRateOri
              ),
            ],
          },
        ];
      }
      this.chartKey = Math.random();
    },
    setRatio() {
      if (this.chartData.ratios.length) {
        this.defineOptions = {
          legend: { itemGap: 18, type: "scroll" },
          grid: {
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [
              ...this.chartData.stats.map((item) => item.name + item.examDate),
            ],
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
          },
        };
        let ratio = this.selectOptions.find((it) => it.id === this.value);
        this.series = [
          {
            type: "line",
            name: ratio.name,
            data: [
              ...this.chartData.stats.map((item) => {
                let ratio = item.classData.ratios.find(
                  (it) => it.id === this.value
                );
                return ratio.total;
              }),
            ],
          },
        ];
      }
      this.chartKey = Math.random();
    },
    setTopSections() {
      if (this.chartData.topSections.length) {
        this.defineOptions = {
          legend: { itemGap: 18, type: "scroll" },
          grid: {
            containLabel: true,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            data: [
              ...this.chartData.stats.map((item) => item.name + item.examDate),
            ],
            axisLabel: {
              show: true,
              interval: 0,
              width: 100,
              overflow: "breakAll",
            },
          },
        };
        let top = this.selectOptions.find((item) => item.id === this.value);
        this.series = [
          {
            type: "line",
            name: top.name,
            data: [
              ...this.chartData.stats.map((item) => {
                let topSection = item.classData.topSections.find(
                  (it) => it.id === this.value
                );
                return topSection.total;
              }),
            ],
          },
        ];
      }
      this.chartKey = Math.random();
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.development);
        });
      }
    },
    electiveClick(item) {
      this.electiveId = item.id;
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    setting() {
      this.visible = true;
    },
    getClassType() {
      if (this.subjectId > 3 || this.electiveId !== null) {
        return "教学";
      }
      return "行政";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../new-exam.module.scss";
.compare {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  color: #3e4551;
  font-size: 14px;
  margin-bottom: 18px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btns-item {
    border-radius: 4px;
    border: 1px solid #d5d6db;
    box-sizing: border-box;
    padding: 6px 18px;
    color: #0a1119;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: #2474ed;
      background-color: #2474ed;
      color: #ffff;
    }
  }
  .btns-active {
    border-color: #2474ed;
    background-color: #2474ed;
    color: #ffff;
  }
  .btns-item + .btns-item {
    margin-left: 8px;
  }
}
.exam-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item + .filter-item {
    margin-left: 8px;
  }
}
::v-deep .ant-select-selection--single {
  height: 35px;
  line-height: 35px;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
</style>
